import React, { Component } from 'react';
import { Modal } from '../Modal/modal';

import { auth } from '../../firebase';
import whiteArrow from '../../assets/white-arrow@2x.png'
import './password-change.css'

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE, displayAlert: false, displayErrorAlert: false };
  }

  onSubmit = (event) => {
    const { passwordOne, passwordTwo } = this.state;

    if (passwordOne !== passwordTwo) {
      var error = new Error("Podane hasła nie są identyczne.")
      this.setState({ error: error, displayErrorAlert: true, passwordOne: '', passwordTwo: '' });
    } else
      auth.doPasswordUpdate(passwordOne)
        .then(() => {
          this.setState(() => ({ ...INITIAL_STATE, displayAlert: true }));
        })
        .catch(error => {
          if (error.code === "auth/weak-password")
            error.message = "Podane hasło musi mieć co najmniej 6 znaków."
          else
            error.message = "Coś poszło nie tak. Spróbuj zalogować się ponownie do portalu."

          this.setState({ error: error, displayErrorAlert: true, passwordOne: '', passwordTwo: '' });
        });

    event.preventDefault();
  }

  render() {
    const {
      passwordOne,
      passwordTwo,
      error,
      displayAlert,
      displayErrorAlert
    } = this.state;

    const { email } = this.props;
    return (
      <div className='change-password-container'>
        <div>
          <Modal title={'Hasło zostało zresetowane.'}
            subtitle={'Wiadomość resetująca hasło została wysłana na adres: ' + email}
            modalState={displayAlert}
            actionButton1={() => this.setState({ displayAlert: false })}
            actionButtonName1={'OK'} />
          {error !== null ? <Modal title={'Wystąpił błąd.'}
            subtitle={error.message}
            modalState={displayErrorAlert}
            actionButton1={() => this.setState({ displayErrorAlert: false })}
            actionButtonName1={'OK'} /> : null}
        </div>
        <div>
          <label className='change-password-title'>Zresetuj hasło.</label>
        </div>
        <div>
          <label className='change-password-subtitle'> Zresetuj hasło dla {email}</label>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className='change-password-labels'>
            <label className='password-label'>Hasło</label> <br />
            <label className='confirm-password-label'>Potwierdź hasło</label>
          </div>
          <div className='change-password-values'>
            <input
              className='account-textfield'
              value={passwordOne}
              onChange={event => this.setState(updateByPropertyName('passwordOne', event.target.value))}
              type="password"
              placeholder="Wprowadź nowe hasło"
            /> <br />
            <input
              className='account-textfield'
              value={passwordTwo}
              onChange={event => this.setState(updateByPropertyName('passwordTwo', event.target.value))}
              type="password"
              placeholder="Potwierdź nowe hasło"
            />
          </div>
          <button className='change-password-button' type="submit">
            <img className="white-arrow-raport-btn" alt='' src={whiteArrow} height={14} width={14} />
            Resetuj hasło
        </button>
        </form>
      </div>
    );
  }
}

export default PasswordChangeForm;