import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { auth } from '../../firebase';
import * as routes from '../../constants/routes';
import './password-forget.css'
import redArrow from '../../assets/red-arrow@2x.png';
import whiteArrow from '../../assets/white-arrow@2x.png'

const PasswordForgetPage = () =>
  <div className="background-gradient">
    <div className="password-forget-form">
      <p className="password-forget-title">Przypomnienie hasła</p>
      <PasswordForgetForm />
    </div>
  </div>

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    auth.doPasswordReset(email)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      error,
    } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <input
          className='email-field'
          value={this.state.email}
          onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
          type="text"
          placeholder="Adres e-mail"
        />
        <button className="reset-btn" disabled={isInvalid} type="submit">
          <img className="white-arrow" alt='' src={whiteArrow} height={18} width={18} />
          <label className='reset-btn-title'>Zresetuj hasło</label>
        </button>
        {error && <label className='forget-password-error-label'>Podany adres e-mail jest niepoprawny.</label>}
      </form>
    );
  }
}

const PasswordForgetLink = () =>
  <p>
    <Link to={routes.PASSWORD_FORGET} className='forget-password-button' style={{ textDecoration: 'none' }}>
      Zapomniałeś hasła?
    </Link>
    <img className="red-arrow" alt='' src={redArrow} height={18} width={18} />
  </p>

export default PasswordForgetPage;

export {
  PasswordForgetForm,
  PasswordForgetLink,
};
