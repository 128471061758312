import { observable, action } from 'mobx';

class EmployeesStore {
    @observable salesList = [];
    @observable serviceList = [];

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action setSalesList = salesList => {
        this.salesList = salesList;
    }

    @action setSerivceList = serviceList => {
        this.serviceList = serviceList;
    }
}

export default EmployeesStore;