import React, { Component } from 'react';
import { compose } from "recompose";
import withAuthorization from "../Session/withAuthorization";
import moment from 'moment';
import XLSX from 'xlsx';
import { db } from '../../firebase';
import './event.css';
import DatePicker from 'react-datepicker';
import { Modal } from '../Modal/modal';
import whiteArrow from '../../assets/white-arrow@2x.png'

export class Event extends Component {
    constructor(props) {
        super(props);
        const { event } = this.props;
        this.state = {
            leads: [],
            editMode: false,
            name: null,
            dateStart: moment.unix(event.dateStart),
            dateEnd: moment.unix(event.dateEnd),
            noLeadsModal: false,
            eventNotStarted: false,
            deleteModal: false,
            showErrorModal: false
        };
        this.editFunction = this.editFunction.bind(this);
        this.editEvent = this.editEvent.bind(this);
        this.startDateChange = this.startDateChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
    }

    componentDidMount() {
        this.getLeads();
    }

    getLeads() {
        db.getLeads().on('value', snap => {
            const leads = snap.val();
            if (leads !== null) {
                this.setState({
                    leads: leads
                })
            }
        })
    }

    downloadLeads(event) {
        const { leads } = this.state;
        var new_workbook = XLSX.utils.book_new();
        let headers = ['timestamp', 'name_first', 'name_last', 'sex', 'clientStatus', 'smtp_addr', 'telephone_f', 'telephone_m']

        if (Date.now() / 1000 < event.dateStart) {
            this.setState({ eventNotStarted: true })
        } else {
            Object.keys(leads).forEach(ipadKey => {
                var ipads = [];
                var leadsPerIPad = [];
                ipads.push(leads[ipadKey]);
                ipads.map((lead) => {
                    Object.keys(lead).forEach(key => {
                        if (lead[key].timestamp >= event.dateStart && lead[key].timestamp <= event.dateEnd) {
                            leadsPerIPad.push(lead[key])
                        }
                    })
                    if (leadsPerIPad.length > 0) {
                        var ws = XLSX.utils.json_to_sheet(leadsPerIPad, { header: headers });
                        ws['!cols'] = [];
                        ws['!cols'][0] = { hidden: true };
                        var sheetName = ipadKey.substr(0, 31)
                        XLSX.utils.book_append_sheet(new_workbook, ws, sheetName);
                    }
                    return leadsPerIPad
                })
            })
            if (new_workbook.SheetNames.length === 0) {
                this.setState({ noLeadsModal: true });
            } else {
                XLSX.writeFile(new_workbook, event.name + ".xlsx");
            }
        }
    }

    editFunction() {
        const { event } = this.props;
        this.setState({
            editMode: true,
            name: event.name,
            dateStart: moment.unix(event.dateStart),
            dateEnd: moment.unix(event.dateEnd),
        })
    }

    editEvent(e) {
        e.preventDefault();
        const { event } = this.props;
        const { dateStart, dateEnd, name } = this.state;
        if (dateStart.unix() >= dateEnd.unix()) {
            this.setState({ showErrorModal: true })
        } else {
            db.updateEvent(name, dateStart.unix(), dateEnd.unix(), event.id)
            this.setState({
                editMode: false
            })
            this.props.updateList();
        }
    }

    startDateChange(date) {
        this.setState({
            dateStart: date
        });
    }

    endDateChange(date) {
        this.setState({
            dateEnd: date
        });
    }

    deleteEvent(event) {
        db.deleteEvent(event.id);
        this.setState({ deleteModal: false });
        this.props.updateList();
    }

    render() {
        const { event } = this.props;
        const { editMode, noLeadsModal, eventNotStarted, deleteModal, showErrorModal } = this.state;
        return (
            <div className={'event-container'}>
                <Modal title={'Nieprawidłowy przedział czasu.'}
                    subtitle={'Podano nieprawidłową datę rozpoczęcia lub zakończenia eventu.'}
                    modalState={showErrorModal}
                    actionButton1={() => this.setState({ showErrorModal: false })}
                    actionButtonName1={'OK'} />
                <Modal title={'Brak ankiet'}
                    subtitle={'Ankiety z ' + event.name + ' nie zostały jeszcze przesłane.'}
                    modalState={noLeadsModal}
                    actionButton1={() => this.setState({ noLeadsModal: false })}
                    actionButtonName1={'OK'} />
                <Modal title={'Event się jeszcze nie rozpoczął'}
                    subtitle={event.name + ' rozpocznie się ' + moment.unix(event.dateStart).format("DD.MM.YYYY HH:mm") + '.'}
                    modalState={eventNotStarted}
                    actionButton1={() => this.setState({ eventNotStarted: false })}
                    actionButtonName1={'OK'}
                />
                <Modal title={'Czy na pewno chcesz usunąć event?'}
                    subtitle={'Usuwając event nie usuwasz danych klientów.'}
                    modalState={deleteModal}
                    actionButton1={() => this.deleteEvent(event)}
                    actionButtonName1={'Usuń'}
                    actionButton2={() => this.setState({ deleteModal: false })}
                    actionButtonName2={'Anuluj'}
                />
                <div className={'edit-event-action-buttons'}>
                    {editMode ? <button className={'edit-event-button'} onClick={this.editEvent}>Zapisz</button> :
                        <button className={'edit-event-button'} onClick={this.editFunction}>Edytuj</button>}
                    {editMode ? <button className={'remove-event-button'} onClick={() => this.setState({ editMode: false, name: null, endDate: null, startDate: null })}>Anuluj</button> :
                        <button className={'remove-event-button'} onClick={() => this.setState({ deleteModal: true })}>Usuń</button>}
                </div>
                {editMode ?
                    <div className={'edit-event-container'}>
                        <div className={'event-labels'}>
                            <label>Nazwa eventu</label> <br />
                            <label style={{ 'marginTop': '9px' }}>Początek eventu</label> <br />
                            <label style={{ 'marginTop': '9px' }}>Koniec eventu</label>
                        </div>
                        <div className={'event-textfields'}>
                            <input required type="text" className='edit-textfield' value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                            <DatePicker
                                className={'edit-event-date'}
                                selected={this.state.dateStart}
                                selectsStart
                                startDate={this.state.dateStart}
                                endDate={this.state.dateEnd}
                                onChange={this.startDateChange}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="LLL"
                                locale="pl"
                                timeCaption="Godzina"
                            />
                            <DatePicker
                                className={'edit-event-date'}
                                selected={this.state.dateEnd}
                                selectsEnd
                                startDate={this.state.dateStart}
                                endDate={this.state.dateEnd}
                                onChange={this.endDateChange}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="LLL"
                                locale="pl"
                                timeCaption="Godzina"
                            />
                        </div>
                    </div>
                    :
                    <div className={'event-details-container'}>
                        <label className={'event-details-title'}>{event.name}</label>
                        <div>
                            <label className={'event-details-date-label'}>
                                {moment.unix(event.dateStart).format("DD.MM.YYYY HH:mm")
                                    + " - " +
                                    moment.unix(event.dateEnd).format("DD.MM.YYYY HH:mm")}
                            </label>
                        </div>
                        <button className={'download-leads-button'}
                            onClick={() => this.downloadLeads(event)}>
                            <img className="white-arrow-raport-btn" alt='' src={whiteArrow} height={14} width={14} />
                            Pobierz ankiety
                        </button>
                    </div>}
            </div>
        );
    }
}

const authCondition = (authUser) => !!authUser;

export default compose(
    withAuthorization(authCondition),
)(Event);