import { db } from './firebase';

export const doCreateEvent = (name, dateStart, dateEnd) => {
    var newRef = db.ref().child('events').push();
    return newRef.set({
        id: newRef.key,
        name,
        dateStart,
        dateEnd,
    });
}

export const updateEvent = (name, dateStart, dateEnd, id) => {
    db.ref().child('events').child(id).update({
        id: id,
        name,
        dateStart,
        dateEnd,
    })
}

export const deleteEvent = (id) =>
    db.ref().child('events').child(id).set(null);

export const getEvents = () =>
    db.ref().child('events');

export const getEvent = (id) =>
    db.ref().child('events').child(id);

export const getLeads = () =>
    db.ref().child('leads');

export const deleteLead = (deviceUIDD, leadKey) =>
    db.ref().child('leads').child(deviceUIDD).child(leadKey).remove()