import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';


const prodConfig = {
  apiKey: "AIzaSyAkQ0UuEkhErm3rEdZHv3qU6VeU3RxrjuY",
  authDomain: "lead-collector-porsche.firebaseapp.com",
  databaseURL: "https://lead-collector-porsche.firebaseio.com",
  projectId: "lead-collector-porsche",
  storageBucket: "lead-collector-porsche.appspot.com",
  messagingSenderId: "1030642251791"
};

const devConfig = {
  apiKey: "AIzaSyDoVKS45p1VoXanLV6LxNAH_R8UPzqHLZI",
    authDomain: "porsche-lead-collector.firebaseapp.com",
    databaseURL: "https://porsche-lead-collector.firebaseio.com",
    projectId: "porsche-lead-collector",
    storageBucket: "porsche-lead-collector.appspot.com",
    messagingSenderId: "13320025926"
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

var firebaseApp = firebase.initializeApp(config);

const db = firebaseApp.database();
const auth = firebaseApp.auth();
const sessionPersistence = firebase.auth.Auth.Persistence.SESSION;
const storage = firebase.storage();

export {
  db,
  auth,
  sessionPersistence,
  storage
};
