import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { auth } from "../../firebase";
import logo from "../../assets/Porsche_logo.png"
import line from "../../assets/line.png"
import './navigation.css'
import { Link } from 'react-router-dom';

export class Navigation extends Component {
    render() {
        const { sessionStore, location } = this.props;
        return (
            <div className="nav-bar-container">
                {sessionStore.authUser
                    ? <NavigationAuth path={location.pathname} />
                    : <Logo />
                }
            </div>
        )
    }
}


const Logo = () =>
    <div>
        <Link to={'/'}><img className="img-logo" alt="" src={logo} /></Link>
        <Link to={'/'}><img className="line-logo-left" alt="" src={line} /></Link>
        <Link to={'/'}><img className="line-logo-right" alt="" src={line} /></Link>
    </div>

const NavigationAuth = ({ path }) =>
    <div>
        <Logo />
        <div className='nav-bar'>
            <button className="btn-logout" onClick={auth.doSignOut}>
                <label>Wyloguj</label>
            </button>
            <Link to={'/account'}
                style={{ textDecoration: 'none', color: 'black' }}
                className={path === "/account" ? 'btn-change-password-bolded' : 'btn-change-password'}>
                Zmień hasło
            </Link>
            <Link to={'/'}
                style={{ textDecoration: 'none', color: 'black' }}
                className={path === "/" ? 'btn-nav-bar-bolded' : 'btn-nav-bar'}>
                Strona główna
            </Link>
            <Link
                to={'/polls'}
                style={{ textDecoration: 'none', color: 'black' }}
                className={path === "/polls" ? 'btn-clinets-bolded' : 'btn-clinets'}>
                Klienci
            </Link>
        </div>
    </div>

export default compose(
    withRouter,
    inject('sessionStore'),
    observer
)(Navigation);
