import React, { Component } from 'react';
import { compose } from 'recompose';
import withAuthorization from '../Session/withAuthorization';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './home.css';
import unselectedArror from '../../assets/icons8-pull-down@2x.png'
import whiteArrow from '../../assets/white-arrow@2x.png'
import { Event } from '../Event/event'

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/minimal-example.css';

class Home extends Component {
    constructor() {
        super();
        this.state = {
            events: [],
        }
        this.updateList = this.updateList.bind(this);
    }

    componentDidMount() {
        this.getEvents();
    }

    getEvents() {
        db.getEvents().once('value', snap => {
            const events = snap.val();
            if (events !== null) {
                var eventsArray = [];
                Object.keys(events).forEach(key => {
                    eventsArray.push(events[key]);
                })
                this.setState({
                    events: eventsArray
                })
            } else {
                this.setState({
                    events: []
                })
            }
        })
    }

    updateList() {
        this.getEvents();
    }

    render() {
        const { events } = this.state;
        return (
            <div className={'home-container'}>
                <label className={'event-list-title'}>Lista eventów</label>
                <Link className={'add-event-button'} to={'addEvent'} style={{ textDecoration: 'none', color: 'white' }}>
                    <button className="table-form-edit-btn">
                        <img className="white-arrow-raport-btn" alt='' src={whiteArrow} height={14} width={14} />
                        Dodaj
                    </button>
                </Link>
                <div>
                    {events.length === 0 ?
                        <label className="no-events-label">Brak zdefiniowanych eventów.</label>
                        :
                        <div className={'event-list-container'}>

                            <Accordion ref="listRef">
                                {
                                    this.state.events.map((event) => {
                                        return (
                                            <AccordionItem key={event.id}>
                                                <AccordionItemTitle>
                                                    <div className={'event-title'}>
                                                        <label className={"event-name"}>{event.name}</label>
                                                        <img className="unselected-arrow" alt='' src={unselectedArror} height={12} width={12} />
                                                        <label className={'event-date-label'}>
                                                            {moment.unix(event.dateStart).format("DD.MM.YYYY HH:mm")
                                                                + " - " +
                                                                moment.unix(event.dateEnd).format("DD.MM.YYYY HH:mm")}
                                                        </label>
                                                    </div>
                                                </ AccordionItemTitle>
                                                <AccordionItemBody>
                                                    <div className={'event-details'}>
                                                        <Event event={event} updateList={() => this.updateList()} />
                                                    </div>
                                                </AccordionItemBody>
                                            </AccordionItem>
                                        )
                                    })
                                }
                            </Accordion>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const authCondition = (authUser) => !!authUser;

export default compose(
    withAuthorization(authCondition)
)(Home);