import { configure } from 'mobx';

import SessionStore from './sessionStore';
import EmployeesStore from "./employeesStore";

configure({ enforceActions: "observed" });

class RootStore {
  constructor() {
      this.sessionStore = new SessionStore(this);
      this.employeesStore = new EmployeesStore(this);
  }
}

const rootStore = new RootStore();

export default rootStore;
