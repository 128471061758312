import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { PasswordForgetLink } from '../PasswordForget';
import { auth } from '../../firebase';
import * as routes from '../../constants/routes';
import { inject, observer } from 'mobx-react';
import './sign-in.css';
import whiteArrow from '../../assets/white-arrow@2x.png'

const SignInPage = ({ history }) =>
  <div className="login">
    <h1 className="sign-in-title">Logowanie do panelu administracyjnego.</h1>
    <div className="login-form">
      <SignInForm history={history} />
    </div>
  </div>

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

@inject('sessionStore')
@observer
class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doSignInWithEmailAndPassword(email, password)
      .then((user) => {
        if (!(user instanceof Error)) {
          this.setState(() => ({ ...INITIAL_STATE }));
          history.push(routes.HOME);
        } else {
          this.setState(() => ({ ...INITIAL_STATE }));
          this.setState(updateByPropertyName('error', user));
        }
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <div>
          <input
            className="email-field"
            value={email}
            onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
            type="text"
            placeholder="Adres email"
          />
          <input
            className="password-field"
            value={password}
            onChange={event => this.setState(updateByPropertyName('password', event.target.value))}
            type="password"
            placeholder="Hasło"
          />
          <PasswordForgetLink />
          <button className="login-button" disabled={isInvalid} type="submit">
            <img className="white-arrow" alt='' src={whiteArrow} height={18} width={18} />
            <label className='login-button-title'>Zaloguj</label>
          </button>
        </div>
        <div>
          {error && <label className='signin-error-label'>Wprowadzony adres e-mail lub hasło są nieprawidłowe.</label>}
        </div>
      </form>
    );
  }
}

export default withRouter(SignInPage);

export {
  SignInForm,
};