import React, { Component } from 'react';
import withAuthorization from "../Session/withAuthorization";
import { compose } from "recompose";
import { db } from '../../firebase';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import * as routes from '../../constants/routes';
import { Modal } from '../Modal/modal';
import 'react-datepicker/dist/react-datepicker.css'
import './add-event.css'
import 'moment/locale/pl'

export class AddEvent extends Component {
  constructor(props) {
    super(props);
    this.addEvent = this.addEvent.bind(this);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.state = {
      startDate: moment(),
      endDate: moment(),
      showErrorModal: false,
    }
  }

  addEvent(e) {
    e.preventDefault();
    const name = this.getName.value;
    const { startDate, endDate } = this.state;
    if (startDate.unix() >= endDate.unix()) {
      this.setState({ showErrorModal: true })
    } else {
      db.doCreateEvent(name, startDate.unix(), endDate.unix()).then(() => {
        this.getName.value = '';
        this.setState({
          startDate: moment(),
          endDate: moment()
        })
        this.props.history.push(routes.HOME);
      })
    }
  }

  startDateChange(date) {
    this.setState({
      startDate: date
    });
  }

  endDateChange(date) {
    this.setState({
      endDate: date
    });
  }

  render() {
    const { showErrorModal } = this.state;
    return (
      <div className="add-event-container">
        <Modal title={'Nieprawidłowy przedział czasu.'}
          subtitle={'Podano nieprawidłową datę rozpoczęcia lub zakończenia eventu.'}
          modalState={showErrorModal}
          actionButton1={() => this.setState({ showErrorModal: false })}
          actionButtonName1={'OK'} />
        <label className={'add-event-title'}>Dodaj nowy event.</label>
        <form onSubmit={this.addEvent} className={'add-event-form'}>
          <div className={'add-event-action-buttons'}>
            <button className={'save-event-button'} type="submit" onClick={this.editEvent}>Zapisz</button>
          </div>
          <div className={'add-event-labels'}>
            <label>Nazwa eventu</label> <br />
            <label style={{'marginTop': '9px'}}>Początek eventu</label> <br />
            <label style={{'marginTop': '8px'}}>Koniec eventu</label>
          </div>
          <div className={'add-event-textfields'}>
            <input className={'add-event-name'} required type="text" ref={(input) => this.getName = input} /><br />
            <DatePicker
              className={'add-event-date'}
              selected={this.state.startDate}
              onChange={this.startDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="LLL"
              locale="pl"
              timeCaption="Godzina"
              selectsStart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            <DatePicker
              className={'add-event-date'}
              selected={this.state.endDate}
              onChange={this.endDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="LLL"
              locale="pl"
              timeCaption="Godzina"
              selectsEnd
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
          </div>
        </form>
      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  withRouter
)(AddEvent);

