import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';

import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import Home from '../Home';
import AccountPage from '../Account';
import withAuthentication from '../Session/withAuthentication';
import * as routes from '../../constants/routes';
import AddEvent from "../AddEvent/AddEvent";
import Polls from "../Polls/polls"
import './index.css';

const App = () =>
  <Router>
    <div>
        <Navigation/>
        <div className="app">
            <Route exact path={routes.SIGN_IN} component={() => <SignInPage />} />
            <Route exact path={routes.PASSWORD_FORGET} component={() => <PasswordForgetPage />} />
            <Route exact path={routes.HOME} component={() => <Home />} />
            <Route exact path={routes.ACCOUNT} component={() => <AccountPage />} />
            <Route exact path={routes.ADD_EVENT} component={() => <AddEvent />}/>
            <Route exact path={routes.POLLS} component={() => <Polls />}/>
        </div>
    </div>
  </Router>

export default withAuthentication(App);