import ReactModal from 'react-modal';
import React from 'react';
import { compose } from "recompose";
import withAuthorization from "../Session/withAuthorization";
import { withRouter } from 'react-router-dom';
import './modal.css';
import whiteArrow from '../../assets/white-arrow@2x.png'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        height: '195px',
        width: '600px',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFFFFFF',
    }
};

export class Modal extends React.Component {
    render() {
        const { title, subtitle, modalState, actionButton1, actionButtonName1, actionButton2, actionButtonName2 } = this.props;
        return (
            <div className={'modal-container'}>
                <ReactModal
                    style={customStyles}
                    isOpen={modalState}
                    ariaHideApp={false}
                    overlayClassName={'overlay'}>
                    <div>
                        <label className={'modal-title'}>
                            {title}
                        </label> <br />
                        <label className={'modal-subtitle'}>
                            {subtitle}
                        </label>
                        <div className={'modal-action-buttons'}>
                            {actionButton2 ?
                                <button className={'modal-action-button'} onClick={actionButton2}>
                                    <img className="white-arrow-raport-btn" alt='' src={whiteArrow} height={14} width={14} />
                                    <label className='action2-button-title'>{actionButtonName2}</label>
                                </button> : null}
                            <button className={'modal-ok-button'} onClick={actionButton1}>
                                <img className="white-arrow-raport-btn" alt='' src={whiteArrow} height={14} width={14} />
                                <label className='action1-button-title'>{actionButtonName1}</label>
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

const authCondition = (authUser) => !!authUser;

export default compose(
    withRouter,
    withAuthorization(authCondition),
)(Modal);