import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import PasswordChangeForm from '../PasswordChange';
import withAuthorization from '../Session/withAuthorization';
import './account.css'

class AccountPage extends React.Component {
  render() {
    const { sessionStore } = this.props;
    return (
      <div className={'account-settings-container'}>
        <PasswordChangeForm email={sessionStore.authUser.email}/>
      </div>
    )
  }
}

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  inject('sessionStore'),
  observer
)(AccountPage);